<template>
  <div>
    <button v-if="user.role === 'superadmin'" @click="recaclculateMessages()">
      Recalculate Messages
    </button>
    <DashboardSuperadmin v-if="user.role === 'superadmin'">
    </DashboardSuperadmin>
    <DashboardBusinessAdmin v-if="user.role === 'business-admin'">
    </DashboardBusinessAdmin>
    <DashboardBranchManager v-if="user.role === 'branch-manager'">
    </DashboardBranchManager>
  </div>
</template>

<script>
import DashboardSuperadmin from "../components/dashboard/DashboardSuperadmin";
import DashboardBusinessAdmin from "../components/dashboard/DashboardBusinessAdmin";
import DashboardBranchManager from "../components/dashboard/DashboardBranchManager";

const firebase = require("../firebaseConfig");

export default {
  name: "Dashboard",
  components: {
    DashboardSuperadmin,
    DashboardBusinessAdmin,
    DashboardBranchManager,
  },
  data: () => ({
    user: {},
    totalMessages: 0,
  }),
  mounted() {
    this.getUserInfo();
  },
  methods: {
    async recaclculateMessages() {
      let businessesFunc = () => {
        let businesses = [];
        return firebase.db
          .collection("businesses")
          .get()
          .then((docs) => {
            docs.forEach((doc) => {
              businesses.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            return businesses;
          });
      };

      let locationsFunc = (businessId) => {
        let locations = [];
        return firebase.db
          .collection(`businesses/${businessId}/locations`)
          .get()
          .then((docs) => {
            docs.forEach((doc) => {
              locations.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            return locations;
          });
      };

      let massTextFunc = (businessId) => {
        let masstexts = [];
        return firebase.db
          .collection(`businesses/${businessId}/mass_texts`)
          .get()
          .then((docs) => {
            docs.forEach((doc) => {
              masstexts.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            console.info(masstexts);
            return masstexts;
          });
      };

      let messagesFunc = (businessId, locationId) => {
        let messages = [];
        return firebase.db
          .collection(
            `businesses/${businessId}/locations/${locationId}/messages`
          )
          .get()
          .then((docs) => {
            docs.forEach((doc) => {
              messages.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            return messages;
          });
      };

      let getMassTextCount = async (businessId) => {
        let obj = {
          totalCampaigns: "",
          masstext: {},
        };

        const massTexts = await massTextFunc(businessId);
        massTexts.forEach((masstext) => {
          obj.masstext = masstext;
          obj.totalCampaigns = massTexts.length;
        });
        return obj;
      };

      let getMessagesCount = async (businessId, locationId) => {
        let obj = {
          totalMsgsSent: 0,
          totalMsgsReceived: 0,
        };

        const messages = await messagesFunc(businessId, locationId);
        messages.forEach((message) => {
          message.to_from == "customer"
            ? obj.totalMsgsReceived++
            : obj.totalMsgsSent++;
        });
        return obj;
      };

      let businesses = await businessesFunc();
      // let totalsent = 0;
      var messages = [];
      // var businessTotal = 0;

      businesses.map(async (business) => {
        const locations = await locationsFunc(business.id);
        // totalsent = 0;

        locations.forEach(async (location) => {
          await firebase.db
            .doc(`businesses/${business.id}/locations/${location.id}`)
            .update({
              location_messages_sent: 0,
              location_messages_received: 0,
            });

          messages = await getMessagesCount(business.id, location.id);

          await firebase.db
            .doc(`businesses/${business.id}/locations/${location.id}`)
            .update({
              location_messages_sent: messages.totalMsgsSent,
              location_messages_received: messages.totalMsgsReceived,
            });

          const masstexts = await getMassTextCount(business.id);
          console.info(masstexts);

          if (masstexts.masstext && masstexts.masstext.id) {
            await firebase.db
              .doc(`businesses/${business.id}/locations/${location.id}`)
              .update({
                location_mass_text_campaigns: masstexts.totalCampaigns,
              });
          }
        });
      });

      setTimeout(() => {
        businesses.map(async (business) => {
          const locations = await locationsFunc(business.id);

          locations.forEach(async (location) => {
            await firebase
              .firestore()
              .doc(`businesses/${business.id}`)
              .update({
                messages_sent: location.location_messages_sent,
                messages_received: location.location_messages_received,
                mass_text_campaigns: location.location_mass_text_campaigns,
              });
          });
        });
      }, 3000);
    },
    getUserInfo() {
      firebase.db
        .collection("users")
        .doc(firebase.auth.currentUser.uid)
        .onSnapshot((doc) => {
          this.user = {
            uid: doc.id,
            ...doc.data(),
          };
        });
    },
  },
};
</script>
