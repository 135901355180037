import { render } from "./DashboardSuperadmin.vue?vue&type=template&id=eb993026&scoped=true"
import script from "./DashboardSuperadmin.vue?vue&type=script&lang=js"
export * from "./DashboardSuperadmin.vue?vue&type=script&lang=js"

import "./DashboardSuperadmin.vue?vue&type=style&index=0&id=eb993026&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-eb993026"
/* hot reload */
if (module.hot) {
  script.__hmrId = "eb993026"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('eb993026', script)) {
    api.reload('eb993026', script)
  }
  
  module.hot.accept("./DashboardSuperadmin.vue?vue&type=template&id=eb993026&scoped=true", () => {
    api.rerender('eb993026', render)
  })

}

script.__file = "src/components/dashboard/DashboardSuperadmin.vue"

export default script