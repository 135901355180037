<template>
  <div class="py-4">
    <h1 class="text-2xl font-semibold text-gray-900 mb-4">Dashboard</h1>

    <div class="border-2 border-gray-200">
      <div class="py-4 bg-gray-200 text-center text-3xl">
        {{ getStringMonth[currentMonth] }}
      </div>
      <div class="text-center py-7">
        <div class="text-1xl">Total Messages Count</div>
        <div class="text-2xl text-color font-semibold mt-2">
          {{ messagesCount }}
        </div>
      </div>
    </div>

    <template v-if="businesses.length == 0">
      <div
        class="border-gray-300 border-2 mt-3 text-center py-3 text-red-500 text-base"
      >
        No businesses yet.
      </div>
    </template>
    <template v-else>
      <div class="font-semibold inline-flex w-full mt-4">
        <div class="w-1/4">Name</div>
        <div class="w-1/4 text-center">Code</div>
        <div class="w-1/4 text-center">Total Messages Sent</div>
        <div class="w-1/4 text-center">Total Received</div>
      </div>

      <div
        class="inline-flex w-full my-2"
        v-for="business in businesses"
        :key="business.id"
      >
        <div class="w-1/4 ">
          <router-link :to="`/dashboard/business/${business.id}`">
            <a class="text-blue-400 capitalize cursor-pointer">
              {{ business.business_name }}
            </a>
          </router-link>
        </div>
        <div class="w-1/4 text-center">{{ business.business_code }}</div>
        <div class="w-1/4 text-center">{{ business.messages_sent }}</div>
        <div class="w-1/4 text-center">{{ business.messages_received }}</div>
      </div>
    </template>
  </div>
</template>

<script>
const firebase = require("../../firebaseConfig");

export default {
  name: "DashboardSuperadmin",
  mounted() {
    this.getBusinesses();
  },
  data: () => ({
    currentMonth: new Date().getMonth(),
    businesses: [],
    messagesCount: 0,
    getStringMonth: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  }),
  methods: {
    getBusinesses() {
      firebase.db.collection("businesses").onSnapshot((docs) => {
        this.businesses = [];
        docs.forEach((doc) => {
          this.businesses.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        this.messagesCount = 0;
        this.businesses.map((business) => {
          this.messagesCount =
            this.messagesCount +
            business.messages_received +
            business.messages_sent;
        });
      });
    },
  },
};
</script>
<style scoped>
.text-color {
  color: #f78201;
}
</style>
